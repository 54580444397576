<template>
  <div
    :class="[
      'input-field',
      `input-${state}`,
      { 'is-disabled': disabled }
    ]"
  >
    <div class="input-wrapper">
      <textarea
        ref="textarea"
        class="input-box"
        :disabled="disabled"
        :value="internalValue"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
        @mouseenter="setHovered(true)"
        @mouseleave="setHovered(false)"
        @mousedown="setActive(true)"
        @mouseup="setActive(false)"
        @keydown="onKeydown"
        :placeholder="placeholder"
        rows="1"
        style="overflow:hidden; resize:none; width:100%;"
      />
      <select
        class="agent-type-dropdown"
        :value="internalAgentTypeValue"
        @change="onAgentTypeChange"
      >
        <option
          v-for="option in agentTypeOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <MiniButton
        class="submit-button"
        type="primary"
        icon="arrow"
        @click="onSubmit"
        :disabled="submitDisabled"
      >
        Submit
      </MiniButton>
    </div>
    <QuestionDropdown
      v-if="showDropdown && questions.length > 0"
      :questions="questions"
      :highlightedIndex="highlightedIndex"
      :showDropdown="showDropdown"
      @select-question="selectQuestion"
      @update-highlighted-index="updateHighlightedIndex"
    />
  </div>
</template>



<script>
import QuestionDropdown from './QuestionDropdown.vue';
import MiniButton from './Buttons/MiniButton.vue'; // Import the PrimaryButton component

export default {
  components: {
    QuestionDropdown,
    MiniButton,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    agentTypeValue: {
      type: String,
      default: 'log_agent',
    },
    agentTypeOptions: {
      type: Array,
      default: () => [
        { value: 'log_agent', label: 'Search Logs' },
        // Add other options here if needed
      ],
    },
    showAgentTypeDropdown: {
      type: Boolean,
      default: true,
    },
    submitDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Enter text',
    },
    questions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      state: 'default',
      internalValue: this.value,
      showDropdown: false,
      highlightedIndex: -1,
      internalAgentTypeValue: this.agentTypeValue,
    };
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
      this.$nextTick(this.adjustHeight);
    },
    agentTypeValue(newValue) {
      this.internalAgentTypeValue = newValue;
    },
  },
  mounted() {
    this.adjustHeight();
    this.internalAgentTypeValue = this.agentTypeValue;

  },
  methods: {
    adjustHeight() {
      const textarea = this.$refs.textarea;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = textarea.scrollHeight + 'px';
      }
    },
    onInput(event) {
      this.internalValue = event.target.value;
      this.$emit('inputField', event);
      this.showDropdown = true;
      this.highlightedIndex = -1;
      this.adjustHeight();
    },
    onFocus() {
      this.setFocused(true);
      this.showDropdown = true;
    },
    onBlur() {
      this.setFocused(false);
      // Delay hiding dropdown to allow click on dropdown items
      setTimeout(() => {
        this.showDropdown = false;
      }, 100);
    },
    onKeydown(event) {
      if (this.showDropdown) {
        if (event.key === 'ArrowDown') {
          event.preventDefault();
          if (this.highlightedIndex < this.questions.length - 1) {
            this.highlightedIndex += 1;
          } else {
            this.highlightedIndex = 0; // Loop back to start
          }
        } else if (event.key === 'ArrowUp') {
          event.preventDefault();
          if (this.highlightedIndex > 0) {
            this.highlightedIndex -= 1;
          } else {
            this.highlightedIndex = this.questions.length - 1; // Loop back to end
          }
        } else if (event.key === 'Enter') {
          if (event.shiftKey || event.ctrlKey || event.metaKey) {
            event.stopPropagation();
          } else if (
            this.highlightedIndex >= 0 &&
            this.highlightedIndex < this.questions.length
          ) {
            event.preventDefault();
            this.selectQuestion(this.questions[this.highlightedIndex]);
          } else {
            // No suggestion selected, emit 'enter' event
            event.preventDefault();
            this.$emit('enter');
          }
        } else if (event.key === 'Escape') {
          this.showDropdown = false;
        }
      } else if (event.key === 'Enter') {
        if (event.shiftKey || event.ctrlKey || event.metaKey) {
          console.log("Enter key pressed with shift, ctrl, or meta key");
          event.stopPropagation();
        } else {
          console.log("Enter key pressed without shift, ctrl, or meta key");
          // No dropdown, enter key pressed
          event.preventDefault();
          this.$emit('enter');
        }
      }
    },
    selectQuestion(question) {
      this.internalValue = question.text;
      this.$emit('inputField', { target: { value: question.text } });
      this.showDropdown = false;
    },
    updateHighlightedIndex(index) {
      this.highlightedIndex = index;
    },
    onAgentTypeChange(event) {
      const value = event.target.value;
      this.$emit('update:agentTypeValue', value);
    },
    onSubmit() {
      this.$emit('submit');
    },
    setFocused(focused) {
      this.state = focused ? 'focused' : 'default';
    },
    setHovered(hovered) {
      if (!this.disabled) {
        this.state = hovered ? 'hover' : 'default';
      }
    },
    setActive(active) {
      if (!this.disabled) {
        this.state = active ? 'active' : 'default';
      }
    },
  },
};
</script>

<style scoped>
.input-field {
  position: relative; /* Needed for absolute positioning of dropdown */
  display: flex;
  width: 800px;
  padding: var(--spacing-3510-px, 6px) 6px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  border-radius: var(--spacing-border-radius-rounded-lg, 8px);
  transition: border-color 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease;
  margin: 8px;
  background: var(--colors-black, #0C160B);
  z-index: 0;
}


.input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}


.input-box {
  width: 100%;
  border: none;
  outline: none;
  /* background: transparent; */
  background: var(--colors-black, #0C160B);
  font-family: Inter, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--colors-white, #FFF);
  resize: none;
  overflow: hidden;
  margin-top: 4px;
}

.input-box::placeholder {
  color: var(--colors-neutral-gray-400, #929691);
  font-weight: 400;
}

.agent-type-dropdown {
  background-color: var(--colors-neutral-gray-50, #1F291F);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 2px solid var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 8px;
  padding: 8px;
  margin-left: 8px;
  font-size: 14px;
  font-family: Inter, Helvetica, Arial, sans-serif;
  cursor: pointer;
  height: 40px;
  min-width: 150px;
}

.agent-type-dropdown:focus {
  outline: none;
  border-color: var(--colors-green-green-500, #40853E);
}

.submit-button {
  margin-left: 8px;
  height: 40px;
}



.input-default {
  border: var(--spacing-052-px, 2px) solid var(--colors-neutral-gray-200, #495048);
}

.input-hover {
  border: var(--spacing-052-px, 2px) solid var(--colors-neutral-gray-300, #6D736D);
  background: var(--colors-black, #0C160B);
}

.input-active {
  border: var(--spacing-052-px, 2px) solid var(--colors-neutral-gray-400, #929691);
  background: var(--colors-black, #0C160B);
}

.input-focused {
  border: var(--spacing-052-px, 2px) solid var(--colors-neutral-gray-200, #495048);
  background: var(--colors-black, #0C160B);
  box-shadow: 0px 0px 1px 4px rgba(255, 255, 255, 0.10);
}

.input-disabled {
  border: var(--spacing-052-px, 2px) solid var(--colors-neutral-gray-200, #495048);
  opacity: 0.4;
}

.is-disabled {
  cursor: not-allowed;
}
</style>
