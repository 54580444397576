<template>
  <div class="multi-metrics-dashboard">
    <div class="dashboard-grid">
      <div v-for="(dashboard, index) in multiMetricsDashboardArtifact.dashboards" :key="index"
        class="dashboard-container">
        <MetricsDashboard :metricsDashboardArtifact="dashboard" />
      </div>
    </div>
  </div>
</template>

<script>
import MetricsDashboard from './MetricsDashboard.vue'

export default {
  name: 'MultiMetricsDashboardArtifact',
  components: {
    MetricsDashboard
  },
  props: {
    multiMetricsDashboardArtifact: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped>
.multi-metrics-dashboard {
  width: 100%;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}

.dashboard-container {
  width: 100%;
}
</style>
