<template>
  <div class="metrics-dashboard">
    <!-- Open Modal Button -->
    <div class="dashboard-link" @click.prevent="openDashboardModal">
      <p class="dashboard-button">
        Open {{ this.metricsDashboardArtifact.name }}
      </p>
      <font-awesome-icon icon="arrow-up-right-from-square" class="dashboard-icon" />
    </div>
    <!-- Full Screen Modal -->
    <div v-if="showDashboardModal" class="modal-overlay" @click.self="closeDashboardModal">
      <div class="modal-content">
        <div class="modal-header">
          <div class="header-content">
            <h3>{{ dashboardTitle }}</h3>
            <div class="modal-buttons">
              <PrimaryButton type="standard" @click="toggleChartType" icon="none">
                View as {{ chartType === 'area' ? 'Columns' : 'Area' }}
              </PrimaryButton>
              <PrimaryButton type="standard" @click="closeDashboardModal">Close</PrimaryButton>
            </div>
          </div>
        </div>
        <div class="modal-body" ref="modalBody">
          <!-- Expanded metric chart -->
          <div v-if="selectedMetricIndex !== null" class="metric-container expanded">
            <div class="metric-actions">
              <MiniButton @click="collapseMetric" type="secondary" icon="minimize"></MiniButton>
              <MiniButton v-if="graphs[selectedMetricIndex].platform_link"
                @click="openLink(graphs[selectedMetricIndex].platform_link)" type="secondary" icon="exitarrow">
              </MiniButton>
            </div>
            <highcharts-chart
              :options="getMetricChartOptions(graphs[selectedMetricIndex].metric, false, selectedMetricOptions)"
              :highcharts="Highcharts" />
          </div>
          <!-- Other metric charts -->
          <div class="modal-metrics-content">
            <div v-for="(graph, index) in graphs" :key="'metric-modal-' + index" :class="'metric-container'"
              :style="{ backgroundColor: selectedMetricIndex === index ? 'var(--colors-neutral-gray-700, #2A2F28)' : 'transparent' }">
              <div class="metric-actions">
                <MiniButton @click="expandMetric(index)" type="secondary" icon="maximize"></MiniButton>
                <MiniButton v-if="graph.platform_link" @click="openLink(graph.platform_link)" type="secondary"
                  icon="exitarrow"></MiniButton>
              </div>
              <highcharts-chart :options="getMetricChartOptions(graph.metric, false, otherMetricOptions)"
                :highcharts="Highcharts" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Chart } from 'highcharts-vue'
import Highcharts from 'highcharts'
import { getMetricChartOptions } from '@/utils/chartUtils'
import PrimaryButton from './Buttons/PrimaryButton.vue'
import MiniButton from './Buttons/MiniButton.vue'


function binarySearchClosest(points, value) {
  let left = 0;
  let right = points.length - 1;

  while (left < right) {
    const mid = Math.floor((left + right) / 2);
    if (points[mid].x === value) return points[mid];
    if (points[mid].x < value) left = mid + 1;
    else right = mid;
  }

  // At this point, `left` should be the closest index
  return points[left];
}


export default {
  name: 'MetricsDashboard',
  components: {
    HighchartsChart: Chart,
    PrimaryButton,
    MiniButton,
  },
  props: {
    // Take in the full artifact because we need to look at metrics, jsons, start/endtime
    metricsDashboardArtifact: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      Highcharts,
      showDashboardModal: false,
      selectedMetricIndex: null,
      chartType: 'area',
      startTime: this.metricsDashboardArtifact.start_time,
      endTime: this.metricsDashboardArtifact.end_time,
    }
  },
  computed: {
    graphs() {
      // return this.metricsDashboardArtifact.graphs.flatMap(graph => graph.metrics);
      console.log(this.metricsDashboardArtifact.graphs)
      return this.metricsDashboardArtifact.graphs;
    },
    selectedMetricOptions() {
      return {
        onChartSelection: this.onChartSelection,
        setSyncLineValue: this.setSyncLineValue,
        chartType: this.chartType,
        timeOfInterest: this.metricsDashboardArtifact.time_of_interest,
      }
    },
    otherMetricOptions() {
      return {
        startTime: this.startTime,
        endTime: this.endTime,
        setSyncLineValue: this.setSyncLineValue,
        chartType: this.chartType,
        timeOfInterest: this.metricsDashboardArtifact.time_of_interest,
      }
    },
    dashboardTitle() {
      const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });
      };
      return `${this.metricsDashboardArtifact.name} from ${formatDate(this.startTime)} to ${formatDate(this.endTime)}`;
    }
  },
  methods: {
    toggleChartType() {
      this.chartType = this.chartType === 'area' ? 'column' : 'area';
    },
    setSyncLineValue(value) {
      Highcharts.charts.forEach(chart => {
        chart.xAxis[0].removePlotLine('crosshair-line');
        chart.xAxis[0].addPlotLine({
          value: value,
          color: 'gray',
          width: 1,
          id: 'crosshair-line',
        });
        // Below draws on tooltips for each graph.
        // TODO: figure out how to make this more efficient because it currently makes my browsers really slow.
        if (chart.series[0]) {
          const series = chart.series[0];
          const points = series.points;
          if (points.length > 0) {
            // Find first point greater than value
            const closestPoint = binarySearchClosest(points, value);
            if (closestPoint) {
              chart.tooltip.refresh(closestPoint);
              // const chartTitle = chart.title.textStr;
              // console.log('chartTitle', chartTitle)
              // console.log('x points', closestPoint.x, this.tooltippedPoints[chartTitle])
              // console.log('tooltippedPoints', { ...this.tooltippedPoints })
              // if (String(this.tooltippedPoints[chartTitle]) !== String(closestPoint.x)) {
              //   console.log("\n\n\n\nNEW POINT\n\n\n\n", chartTitle, closestPoint)
              //   chart.pointer.reset();
              //   chart.tooltip.refresh(points[5]);
              //   // chart.tooltip.refresh(closestPoint);
              //   this.tooltippedPoints[chartTitle] = closestPoint.x;
              // }
            }
          }
        }
      });
    },
    openLink(link) {
      window.open(link, '_blank');
    },
    onChartSelection(event) {
      if (event.resetSelection) {
        this.startTime = this.metricsDashboardArtifact.start_time;
        this.endTime = this.metricsDashboardArtifact.end_time;
        return;
      }

      if (!event || !event.xAxis) return;

      const [xAxis] = event.xAxis;
      this.startTime = new Date(xAxis.min).toISOString();
      this.endTime = new Date(xAxis.max).toISOString();
    },
    getMetricChartOptions,
    openDashboardModal() {
      this.showDashboardModal = true
      window.addEventListener('keyup', this.onKeyUp)
    },
    closeDashboardModal() {
      this.showDashboardModal = false
      this.selectedMetricIndex = null
      window.removeEventListener('keyup', this.onKeyUp)
      Highcharts.charts.forEach(chart => {
        chart.xAxis[0].removePlotLine('crosshair-line');
      });
    },
    onKeyUp(event) {
      if (event.key === 'Escape' || event.key === 'Esc') {
        this.closeDashboardModal()
      }
    },
    expandMetric(index) {
      this.selectedMetricIndex = index
      // Scroll to top of the metric container using ref
      if (this.$refs.modalBody) {
        this.$refs.modalBody.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },
    collapseMetric() {
      this.selectedMetricIndex = null
    }
  }
}
</script>

<style scoped>
.metric-container.expanded {
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 24px;
}


.header-content {
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  width: 100%;
}

.chart-type-toggle {
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-neutral-gray-600, #DBDCDA);
  border: 1px solid var(--colors-neutral-gray-600, #3D423B);
  transition: all 0.2s ease;
}

.chart-type-toggle:hover {
  background-color: var(--colors-neutral-gray-600, #3D423B);
}

.metric-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 8px;
}

.metric-actions button:hover {
  background-color: var(--colors-neutral-gray-600, #3D423B);
}

.metric-actions button:active {
  transform: translateY(1px);
}

.metrics-dashboard {
  gap: 20px;
}

.metric-container {
  min-height: 300px;
  border-radius: 8px;
  padding-right: 12px;
}

.dashboard-link {
  border-radius: 8px;
  border: 2px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;
}

.dashboard-button {
  border-radius: 6px 6px 0px 0px;
  background-color: var(--colors-neutral-gray-700, #2A2F28);
  color: var(--colors-white, #FFF);
  width: 100%;
  text-align: center;
  padding: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.dashboard-icon {
  padding: 16px;
  padding-bottom: 14px;
  font-size: 22px;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--colors-neutral-gray-800, #0C160B);
  border-radius: 12px;
  width: 95%;
  height: 95%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  padding: 20px 10px;
  border-bottom: 1px solid var(--colors-neutral-gray-25-alpha, rgba(255, 255, 255, 0.04));
}

.modal-header h3 {
  color: var(--colors-white, #FFF);
  font-size: 20px;
}

.modal-body {
  flex: 1;
  overflow-y: auto;
  padding: 10px 20px;
}

.modal-metrics-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.modal-buttons {
  display: flex;
  gap: 20px;
}

/* Scrollbar Styles */
.modal-body::-webkit-scrollbar {
  width: 8px;
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: var(--colors-neutral-gray-600, #DBDCDA);
  border-radius: 4px;
}

.modal-body::-webkit-scrollbar-track {
  background-color: var(--colors-neutral-gray-800, #0C160B);
}
</style>
